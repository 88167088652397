import React, { useState, useEffect } from 'react'
import { Seo, Billboard, Button } from '@cgtalks/common/components'
import { graphql, StaticQuery, Link } from 'gatsby'
import Layout from '../molecules/layout'
import { API, Axios } from '@cgtalks/common/utils'
import { isTest } from '@cgtalks/common/service'
import { getCurrentPageVideoStatus } from '../service/video'
import {
  ShareCgOfflineArticle,
  ShareCgOfflineDetailContent,
  ShareCgOfflineLeft,
  ShareCgOfflineSilder,
  ShareCgOfflineDetailFooter,
  ShareCgOfflineContainer,
  LinkToPost,
  OfflineTitle,
  OfflineContentDetailCard,
  ShareOfflineDetailContent,
  SignButton,
  LeftStatus,
  ShowDialog,
  SigningForm,
  Form,
  InputRow,
  ErrorText,
  DialogContent,
} from './share-cg-offline-detail.atom'
import CloseBtn from '../images/offline/close-btn.png'

import { OfflineStatus, OfflineStatusMap } from '@cgtalks/common/constant/type'

const ShareCgOfflineDetail = ({
  location,
  pageContext: { previousSlug, previousTitle, nextSlug, nextTitle, sourceData },
}) => {
  const {
    seoTitle,
    description,
    seoKeywords,
    guests,
    artical,
    image,
    video,
    title,
    status,
    address,
    specificTime,
    peopleNum,
    cost,
    slug,
    isBili,
  } = sourceData

  const [isOpen, setIsOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
  const [currentDetailData, setCurrentDetailData] = useState({})

  const [name, setName] = useState('') // 姓名
  const [company, setCompany] = useState('') // 组织/公司
  const [position, setPosition] = useState('') // 职位
  const [phone, setPhone] = useState('') // 电话
  const [wechat, setWechat] = useState('') // 微信号
  const [email, setEmail] = useState('') // 邮箱
  const [reason, setReason] = useState('') // 申请理由

  const [isNameValid, setIsNameValid] = useState(false)
  const [isCompanyValid, setIsCompanyValid] = useState(false)
  const [isPositionValid, setIsPositionValid] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [isWechatValid, setIsWechatValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isReasonValid, setIsReasonValid] = useState(false)

  const [nameValidText, setNameValidText] = useState('')
  const [companyValidText, setCompanyValidText] = useState('')
  const [positionValidText, setPositionValidText] = useState('')
  const [phoneValidText, setPhoneValidText] = useState('')
  const [wechatValidText, setWechatValidText] = useState('')
  const [emailValidText, setEmailValidText] = useState('')
  const [reasonValidText, setReasonValidText] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    // 表单验证
    if (
      !name ||
      !company ||
      !position ||
      !phone ||
      !reason ||
      isNameValid ||
      isCompanyValid ||
      isPositionValid ||
      isPhoneValid ||
      isWechatValid ||
      isEmailValid ||
      isReasonValid
    ) {
      alert('请填写完整信息')
      return
    }
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      return
    }
    if (email && !/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(email)) {
      return
    }
    const data = {
      name: name.trim(),
      company: company.trim(),
      position: position.trim(),
      phone,
      wechat: wechat.trim(),
      email: email.trim(),
      reason: reason.trim(),
      type: 2,
      createTime: Date.now(),
    }
    instance
      .post(API.event.guest, {
        data,
        event: 'cgForm',
      })
      .then((res) => {
        if (res.code === 200) {
          setSubmitted(true)
          setIsOpen(false)
        }
      })
  }

  const handleNameChange = (e) => {
    const value = e.target.value
    setName(value)
    if (value.trim() === '') {
      setIsNameValid(true)
      setNameValidText('必填')
    } else if (value.trim().length > 200) {
      setIsNameValid(true)
      setNameValidText('输入不超过200字符')
    } else {
      setIsNameValid(false)
      setNameValidText('')
    }
  }

  const handleCompanyChange = (e) => {
    const value = e.target.value
    setCompany(value)
    if (value.trim() === '') {
      setIsCompanyValid(true)
      setCompanyValidText('必填')
    } else if (value.trim().length > 200) {
      setIsCompanyValid(true)
      setCompanyValidText('输入不超过200字符')
    } else {
      setIsCompanyValid(false)
      setCompanyValidText('')
    }
  }

  const handlePositionChange = (e) => {
    const value = e.target.value
    setPosition(value)
    if (value.trim() === '') {
      setIsPositionValid(true)
      setPositionValidText('必填')
    } else if (value.trim().length > 200) {
      setIsPositionValid(true)
      setPositionValidText('输入不超过200字符')
    } else {
      setIsPositionValid(false)
      setPositionValidText('')
    }
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value
    setPhone(value)
    if (value.trim() === '') {
      setIsPhoneValid(true)
      setPhoneValidText('必填')
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      setIsPhoneValid(true)
      setPhoneValidText('手机号码格式不正确')
    } else {
      setIsPhoneValid(false)
      setPhoneValidText('')
    }
  }

  const handleWechatChange = (e) => {
    const value = e.target.value
    setWechat(value)
    if (value.trim().length > 200) {
      setIsWechatValid(true)
      setWechatValidText('输入不超过200字符')
    } else {
      setIsWechatValid(false)
      setWechatValidText('')
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    if (value && !/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
      setIsEmailValid(true)
      setEmailValidText('邮箱格式不正确')
    } else {
      setIsEmailValid(false)
      setEmailValidText('')
    }
  }

  const handleReasonChange = (e) => {
    const value = e.target.value
    setReason(value)
    if (value.trim() === '') {
      setIsReasonValid(true)
      setReasonValidText('必填')
    } else if (value.trim().length > 500) {
      setIsReasonValid(true)
      setReasonValidText('输入不超过500字符')
    } else {
      setIsReasonValid(false)
      setReasonValidText('')
    }
  }

  const confirm = () => {
    setSubmitted(false)
    setName('')
    setCompany('')
    setPosition('')
    setPhone('')
    setWechat('')
    setEmail('')
    setReason('')
  }

  const cancelSign = () => {
    setIsOpen(false)
    setName('')
    setCompany('')
    setPosition('')
    setPhone('')
    setWechat('')
    setEmail('')
    setReason('')
  }

  const openDialog = () => {
    if (status === OfflineStatus.SIGNING) {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    if (
      isNameValid ||
      isCompanyValid ||
      isPositionValid ||
      isPhoneValid ||
      isWechatValid ||
      isEmailValid ||
      isReasonValid ||
      !name ||
      !company ||
      !position ||
      !phone ||
      !reason
    ) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [
    isNameValid,
    isCompanyValid,
    isPositionValid,
    isPhoneValid,
    isWechatValid,
    isEmailValid,
    isReasonValid,
    name,
    company,
    position,
    phone,
    reason,
  ])

  useEffect(() => {
    async function getCardData() {
      const result = await getCurrentPageVideoStatus([sourceData], true)
      setCurrentDetailData(result[0])
    }
    getCardData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceData])

  return (
    <StaticQuery
      query={graphql`
        query {
          bannerBg: file(relativePath: { eq: "offline/offline-bg.png" }) {
            ...fluidImage
          }
          bannerMbBg: file(relativePath: { eq: "offline/offline-bg-mb.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ bannerBg, bannerMbBg }) => (
        <Layout location={location}>
          <Seo
            title={`${seoTitle}-泛CG聚未来`}
            description={description}
            keywords={seoKeywords}
            sharePostSlug={`cgactivity/${slug}.html`}
            shareImgSrc={image[0]}
          />
          <ShareCgOfflineContainer>
            <Billboard
              className='cgoffline-detail-bg'
              fluid={[
                bannerBg.childImageSharp.fluid,
                { ...bannerMbBg.childImageSharp.fluid, media: `(max-width: 780px)` },
              ]}
            />
            <OfflineContentDetailCard>
              {status === OfflineStatus.SIGNING && <LeftStatus>报名中</LeftStatus>}
              {isBili ? (
                <iframe
                  src={`//player.bilibili.com/player.html?bvid=${currentDetailData.bvid}`}
                  scrolling='no'
                  border='0'
                  frameborder='no'
                  framespacing='0'
                  allowfullscreen='true'
                  title='bili-live'
                  width='100%'
                  height='100%'
                ></iframe>
              ) : video ? (
                <video src={video[0]} controls />
              ) : (
                <img src={image[0]} alt={title} />
              )}
              <ShareOfflineDetailContent>
                <h1 className='offline-share-title'>{title}</h1>
                <div className='offline-share-text'>
                  <span>活动状态：</span>
                  <span style={{ color: OfflineStatusMap.get(status).color, fontWeight: 500 }}>
                    {OfflineStatusMap.get(status).text}
                  </span>
                </div>
                <div className='offline-share-text'>
                  <span>活动时间：</span>
                  <span>{specificTime}</span>
                </div>
                <div className='offline-share-text'>
                  <span>活动地点：</span>
                  <span>{address}</span>
                </div>
                <div className='offline-share-text'>
                  <span>报名人数：</span>
                  <span className='people-num'>{peopleNum}</span>
                </div>
                <div className='offline-share-text'>
                  <span>报名费用：</span>
                  <span style={{ color: '#00FFAB', fontWeight: 500 }}>
                    {cost === 0 ? '免费' : cost}
                  </span>
                </div>
                <SignButton isSign={status === OfflineStatus.SIGNING} onClick={() => openDialog()}>
                  我要报名
                </SignButton>
              </ShareOfflineDetailContent>
            </OfflineContentDetailCard>
            <ShareCgOfflineDetailContent>
              <ShareCgOfflineLeft>
                <OfflineTitle>线下活动介绍</OfflineTitle>
                <ShareCgOfflineArticle>
                  <div className='cgoffline-content'>
                    {React.createElement('div', {
                      dangerouslySetInnerHTML: { __html: artical.artical },
                    })}
                  </div>
                </ShareCgOfflineArticle>
                <ShareCgOfflineDetailFooter>
                  <LinkToPost>
                    上一篇：
                    {previousSlug ? (
                      <Link to={`/cgactivity/${previousSlug}.html`}>{previousTitle}</Link>
                    ) : (
                      '没有了'
                    )}
                  </LinkToPost>
                  <LinkToPost>
                    下一篇：
                    {nextSlug ? (
                      <Link to={`/cgactivity/${nextSlug}.html`}>{nextTitle}</Link>
                    ) : (
                      '没有了'
                    )}
                  </LinkToPost>
                </ShareCgOfflineDetailFooter>
              </ShareCgOfflineLeft>
              <ShareCgOfflineSilder>
                {guests &&
                  guests.map((item) => {
                    return (
                      <div key={item.title} className='guest-content'>
                        <img src={item.avatar[0]} alt='' className='guest-avatar' />
                        <div>
                          <p className='guest-title'>{item.name}</p>
                          <p className='guest-subTitle'>{item.position}</p>
                        </div>
                      </div>
                    )
                  })}
              </ShareCgOfflineSilder>
            </ShareCgOfflineDetailContent>
            {isOpen && (
              <ShowDialog>
                <SigningForm>
                  <img src={CloseBtn} alt='关闭' onClick={() => cancelSign()} />
                  <h1>线下交流会报名申请</h1>
                  <Form onSubmit={handleSubmit}>
                    <div className='fom-row'>
                      <InputRow>
                        <label htmlFor='name'>*您的姓名</label>
                        <input
                          id='name'
                          type='text'
                          value={name}
                          onBlur={handleNameChange}
                          onChange={handleNameChange}
                          placeholder='请填写您的真实姓名'
                        />
                      </InputRow>
                      {isNameValid && <ErrorText>{nameValidText}</ErrorText>}
                      <InputRow>
                        <label htmlFor='company'>*组织/公司</label>
                        <input
                          id='company'
                          type='text'
                          value={company}
                          onBlur={handleCompanyChange}
                          onChange={handleCompanyChange}
                          placeholder='请填写您的组织或公司名称'
                        />
                      </InputRow>
                      {isCompanyValid && <ErrorText>{companyValidText}</ErrorText>}
                      <InputRow>
                        <label htmlFor='position'>*您的职位</label>
                        <input
                          id='position'
                          type='text'
                          value={position}
                          onBlur={handlePositionChange}
                          onChange={handlePositionChange}
                          placeholder='请填写您的职位'
                        />
                      </InputRow>
                      {isPositionValid && <ErrorText>{positionValidText}</ErrorText>}
                      <InputRow>
                        <label htmlFor='phone'>*您的电话</label>
                        <input
                          id='phone'
                          type='tel'
                          value={phone}
                          pattern='[0-9]{11}'
                          onBlur={handlePhoneChange}
                          onChange={handlePhoneChange}
                          placeholder='请正确填写您的联系电话'
                        />
                      </InputRow>
                      {isPhoneValid && <ErrorText>{phoneValidText}</ErrorText>}
                      <InputRow>
                        <label htmlFor='wechat'>您的微信号</label>
                        <input
                          id='wechat'
                          type='tel'
                          value={wechat}
                          onBlur={handleWechatChange}
                          onChange={handleWechatChange}
                          placeholder='请填写您的微信号'
                        />
                      </InputRow>
                      {isWechatValid && <ErrorText>{wechatValidText}</ErrorText>}
                      <InputRow>
                        <label htmlFor='email'>您的邮箱</label>
                        <input
                          id='email'
                          type='email'
                          value={email}
                          onBlur={handleEmailChange}
                          onChange={handleEmailChange}
                          placeholder='请填写您的邮箱'
                        />
                      </InputRow>
                      {isEmailValid && <ErrorText>{emailValidText}</ErrorText>}
                      <InputRow className='text-area'>
                        <label htmlFor='reason'>*简单描述下申请理由</label>
                        <textarea
                          id='reason'
                          value={reason}
                          onBlur={handleReasonChange}
                          onChange={handleReasonChange}
                          placeholder='请简单描述下您的申请理由，以便我们尽快审核通过，500字以内'
                        />
                      </InputRow>
                      {isReasonValid && (
                        <ErrorText mt={'3px'} className='textarea-error'>
                          {reasonValidText}
                        </ErrorText>
                      )}
                    </div>
                    <Button disabled={disableSubmit}>提交申请</Button>
                  </Form>
                </SigningForm>
              </ShowDialog>
            )}
            {submitted && (
              <ShowDialog>
                <DialogContent>
                  <p className='success'>提交成功</p>
                  <p className='content-text'>感谢您的报名，我们将尽快通知您报名结果</p>
                  <button onClick={confirm}>好的</button>
                </DialogContent>
              </ShowDialog>
            )}
          </ShareCgOfflineContainer>
        </Layout>
      )}
    />
  )
}

export default ShareCgOfflineDetail
