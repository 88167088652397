import { API, Axios } from '@cgtalks/common/utils'
import { LiveStatus } from '@cgtalks/common/constant/type'
const instance = new Axios(API.auth.videoHost, false)

const getCurrentLiveInformation = (data) => {
  const { room_id, bvid, status } = data[0];
  if (status === LiveStatus.NOTLIVE) return; 
  if (status === LiveStatus.LIVE) {
    return {
      videoUrl: `https://live.bilibili.com/${room_id}`
    };
  }
  return  {
    bvid: data.length > 1 ? data.find(item => item.bvid)?.bvid : bvid,
  }
}

export const getCurrentPageVideoStatus = async (data: any, isDetailPage?: boolean) => {
  const result = await instance.get(API.event.cgOnlineVideo)
  const resultData = result ? result.allVideos : []
  return data.map((item) => {
    const filterData = resultData.filter((p) => p.title.replace(/\s/g, '') === (isDetailPage ? item.title.replace(/\s/g, '') : item.node.title.replace(/\s/g, '')))
    if (!filterData.length) return item;
    if (!isDetailPage) return {...item, ...Object.assign(item.node, { status: filterData[0].status })};
    return {...item, ...{ status: filterData[0].status, ...getCurrentLiveInformation(filterData) }};
  })
}
