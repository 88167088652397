import styled from 'styled-components'
import AvatarBg from '../images/offline/avatar-bg.png'
import AvatarBgMb from '../images/offline/avatar-bg-mb.png'
import { typography, Media, color, mbSize } from '@cgtalks/common/theme'
import { Flex } from '@cgtalks/common/components'

export const ShareCgOfflineContainer = styled.div`
  width: 1400px;
  margin: 100px auto;
  padding: 0 20px;
  .cgoffline-detail-bg {
    height: 620px;
    min-width: 1440px;
  }
  .visit-btn {
    border: 1px solid #00ffab;
    background: transparent;
    color: #00ffab;
    margin-right: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    margin: 100px auto 50px;
    padding: 0;
    .cgoffline-detail-bg {
      height: ${mbSize(1000)};
      width: 100%;
      min-width: unset;
    }
  }
`

export const OfflineTitle = styled.div`
  display: flex;
  font-size: ${typography.h3};
  font-weight: bold;
  color: #f3f6f7;
  margin-bottom: 27px;
  align-items: center;
  ::before {
    content: '';
    width: 8px;
    height: 24px;
    background: #00ffab;
    border-radius: 3px;
    margin-right: 20px;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: 23px;
    margin-top: 17px;
  }
`
export const ShareCgOfflineSilder = styled.div`
  margin-left: 40px;
  margin-top: 60px;
  width: 440px;
  .guest-content {
    height: 200px;
    background: #2b2b37;
    border-radius: 4px;
    display: flex;
    margin-bottom: 20px;
    color: ${color.white};
    padding: 16px 37px 24px 30px;
    box-sizing: border-box;
    align-items: center;
  }
  .guest-avatar {
    background-image: url(${AvatarBg});
    background-repeat: no-repeat;
    padding: 20px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .guest-title {
    font-size: ${typography.h3};
    margin: 10px 0;
  }
  .guest-subTitle {
    font-size: ${typography.text};
    margin: 10px 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    margin-top: 15px;
    width: unset;
    .guest-content {
      height: 150px;
      margin-bottom: 15px;
      color: ${color.white};
      padding: 15px 30px;
    }
    .guest-avatar {
      background-image: url(${AvatarBgMb});
      background-repeat: no-repeat;
      padding: 15px;
      width: 90px;
      height: 90px;
      margin-right: 16.5px;
    }
    .guest-title {
      font-size: ${typography.h4};
    }
    .guest-subTitle {
      font-size: ${typography.textThin};
    }
  }
`
export const ShareCgOfflineDetailFooter = styled.div`
  display: flex;
  flex-direction: column;
`
export const LinkToPost = styled.p`
  color: ${color.white};
  font-size: ${typography.textSmall};
  margin: 0 0 25px;
  a {
    color: ${color.white};
    text-decoration: none;
    border: 0 !important;
    :hover {
      color: #00ffab;
    }
  }
  :hover {
    color: #00ffab;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: 20px;
  }
`
export const ShareCgOfflineLeft = styled.div`
  width: 770px;
  flex: 1;
  height: fit-content;
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`

export const ShareCgOfflineArticle = styled.div`
  background-color: #2b2b37;
  padding: 40px 20px 35px 50px;
  margin-bottom: 60px;
  border-radius: 4px;
  .cgoffline-content {
    margin-right: 20px;
  }
  img {
    max-width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    padding: 36px 18px 63px;
    margin-bottom: 30px;
    .cgoffline-content {
      margin-right: 0;
    }
  }
`

export const ShareCgOfflineDetailContent = styled.div`
  color: ${color.white};
  margin: auto auto 46px;
  display: flex;
  ${Media.lessThan(Media.small)} {
    flex-direction: column-reverse;
    margin-bottom: 26px;
  }
`

export const OfflineContentDetailCard = styled.div`
  width: 1400px;
  margin: 100px auto 40px;
  height: 520px;
  background: #2b2b37;
  border-radius: 4px;
  display: flex;
  video,
  iframe {
    width: 700px;
    height: 520px;
    object-fit: cover;
  }
  img {
    width: 700px;
    height: 520px;
    object-fit: contain;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: 0;
    height: auto;
    flex-direction: column;
    img,
    video {
      width: 100%;
      height: 69.33vw;
    }
  }
`

export const ShareOfflineDetailContent = styled.div`
  padding: 30px 50px 47px 40px;
  max-width: 700px;
  box-sizing: border-box;
  .offline-share-title {
    font-size: ${typography.title};
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: ${color.white};
    line-height: 48px;
  }
  .offline-share-text {
    line-height: 35px;
    display: flex;
    span:first-child {
      font-size: ${typography.textSmall};
      font-weight: 400;
      color: #c6c6d2;
      min-width: 70px;
    }
    span:last-child {
      font-family: Source Han Sans CN;
      font-size: ${typography.text};
      font-weight: 400;
      color: ${color.white};
    }
    .people-num {
      font-size: ${typography.h2} !important;
      font-family: 'Helvetica' !important;
      font-weight: bold !important;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 20px 10px 30px;
    .offline-share-title {
      font-size: ${typography.h4};
      line-height: 24px;
      margin-bottom: 20px;
    }
    .offline-share-text {
      line-height: 26px;
      span:last-child {
        font-size: ${typography.textSmall};
      }
      .people-num {
        font-size: ${typography.h4} !important;
      }
    }
  }
`

export const SignButton = styled.button`
  width: 160px;
  height: 50px;
  background: ${(p) => (p.isSign ? '#00ffab' : '#383844')};
  border-radius: 25px;
  color: ${(p) => (p.isSign ? '#21252a' : '#21252A')};
  font-size: ${typography.h4};
  font-weight: 500;
  cursor: ${(p) => (p.isSign ? 'pointer' : 'not-allowed')};
  border: 0;
  margin-top: 40px;
  &:hover {
    background: ${(p) => p.isSign && 'linear-gradient(-90deg, #0fd2b7 0%, #25cf6b 100%)'};
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 23px;
    width: 100%;
    height: ${mbSize(70)};
    font-size: 15px;
  }
`

export const LeftStatus = styled.div`
  position: absolute;
  width: 92px;
  height: 30px;
  background: #f14c40;
  border-radius: 0 0 20px 0;
  font-size: ${typography.textSmall};
  font-weight: bold;
  color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 66px;
    height: 21px;
    margin-left: -5px;
    font-size: ${typography.textThin};
    border-radius: 0 0 15px 0;
  }
`
export const ShowDialog = styled.div`
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SigningForm = styled.div`
  width: 800px;
  max-height: 95vh;
  background: #2e2f3a;
  margin: 100px auto;
  color: ${color.white};
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 23px;
  position: relative;
  h1 {
    text-align: center;
  }
  img {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 23px;
  }
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    padding: 10px 0 31px;
    height: auto;
    min-height: unset;
    margin: 40px auto 35px;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 14px;
    }
  }
`
export const Form = styled.form`
  margin: auto;
  text-align: right;
  button {
    width: 160px;
    display: flex;
    justify-content: center;
    margin: 30px auto 47px;
    background: #00ffab;
    color: #21252a;
    &:disabled {
      cursor: not-allowed;
      filter: unset !important;
      background-color: #383844 !important;
      color: #21252a !important;
      font-size: 16px;
      box-shadow: none !important;
      border: none !important;
    }
  }
  .fom-row {
    max-height: 57vh;
    overflow: auto;
    padding: 0 140px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 4px;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 0 0 20px;
    text-align: left;
    button {
      width: 95%;
      height: 35px;
      margin: 27px 0 0;
      font-size: 15px;
    }
    .fom-row {
      max-height: 60vh;
      overflow-y: scroll;
      padding: 0 20px 0 0;
    }
  }
`

export const InputStyle = `
  flex-grow: 1;
  color: white;
  border: 1px solid #515160;
  border-radius: 4px;
  width: 401px;
  background: #383844;
  font-size: 14px;
  box-sizing: border-box;
  ::placeholder { color: #7B7B8B; }
  :focus-visible { outline:none; }

  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
export const ErrorText = styled.div`
  color: #f14c40;
  font-size: ${typography.textSmall};
  text-align: left;
  margin-left: 20%;
  margin-top: ${(p) => (p.mt ? p.mt : '-8px')};
  &.textarea-error {
    margin-left: 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    margin-top: 2px;
  }
`

export const InputRow = styled(Flex)`
  font-size: ${typography.textSmall};
  line-height: 60px;
  label {
    font-weight: bold;
    color: ${color.white};
    width: 100px;
    text-align: left;
  }
  input {
    ${InputStyle};
    height: 37px;
    padding: 0 10px;
  }
  textarea {
    ${InputStyle};
    height: 100px;
    padding: 10px;
    max-height: 200px;
  }
  &.text-area {
    align-items: baseline;
    flex-direction: column;
    label,
    textarea {
      width: 100%;
    }
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    line-height: 40px;
    align-items: flex-start;
    label {
      margin: 0;
    }
    &.text-area {
      align-items: flex-start;
    }
  }
`

export const DialogContent = styled.div`
  background-color: #2e2f3a;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  button {
    border: none;
    text-align: center;
    width: 120px;
    height: 40px;
    background: #00ffab;
    border-radius: 20px;
  }
  .success {
    font-size: ${typography.h3};
    font-weight: 500;
    color: #00ffab;
    margin: 0;
  }
  .content-text {
    font-size: ${typography.text};
    color: ${color.white};
    margin: 23px 0;
  }
  ${Media.lessThan(Media.small)} {
    padding: 20px 20px;
    button {
      width: 110px;
      height: 30px;
    }
    p {
      font-size: ${typography.textSmall};
    }
  }
`
